import React from 'react';
import PassLink from '../pass-link/pass-link';
import './cta.scss';

const CTAContent = ({title, type, background}) => {
  return (
    <>
    {type === "video"
      ?   <video className="ahq-cta__image" playsInline autoPlay muted loop>
            <source src={background} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
      : <span className="ahq-cta__image" style={{backgroundImage: `linear-gradient(#000, #000), url(${background})`}} />
    }
    <h3 className="ahq-cta__title">
      {title}
    </h3>
    </>
  )
}

const CTA = ({title, link, framed = false, background = "none", type = "img"}) => {
  return (
    <li className={`ahq-cta ${framed?"ahq-cta--framed":""}`}>
      {link?(
        <PassLink link={link}>
          <CTAContent title={title} background={background} type={type} />
        </PassLink>
        ):<CTAContent title={title} background={background} type={type} />
      }
    </li>
  )
}

export default CTA;