import React from 'react';
import './modal.scss';

const Modal = ({children, isVisible = false, onClose}) => {
  return (
    <aside className={`ahq-modal ${isVisible?"ahq-modal--visible": ''}`}>
      <div className="ahq-modal__overlay" onClick={onClose} />
        <button className="ahq-modal__close" onClick={onClose}>X</button>
        <section className='ahq-modal__container'>
          {children}
        </section>
    </aside>
  );
}

export default Modal;