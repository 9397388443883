import React from 'react';
import './site-logo.scss';

const SiteLogo = () => {
  return (
    <h1 className="ahq-site-logo">
      ADE HQ
    </h1>
  )
}

export default SiteLogo;