import './assets/styles/styles.scss';
import HomeView from './views/home-view';
import React, {createContext, useEffect, useState} from 'react';
// import config from './site-config.json';

// export const ConfigContext = createContext(config);
export const ConfigContext = createContext({});

function App() {
  const [config, setConfig] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    if (config) setIsLoading(false);
  }, [config]);

  useEffect(()=>{
    fetch('/site-config.json').then(response => response.json()).then((data)=>{
      setConfig(data)
    })
  },[]);

  return (
    <ConfigContext.Provider value={config}>
      {isLoading?'':<HomeView />}
    </ConfigContext.Provider>
  );
}

export default App;
