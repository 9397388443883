import React, {useContext} from 'react';
import {ConfigContext} from '../../App';
import './text-block.scss';

const TextBlock = () => {
  const textContent = useContext(ConfigContext).home.presentation || [];
  return (
    <article className="ahq-text-block">
      {textContent.map(text => <p key={window.crypto.randomUUID()} className='ahq-text-block__paragraph'>{text}</p>)}
    </article>
  )
}

export default TextBlock;