import React, {useContext} from 'react';
import { ConfigContext } from '../../App';
import CTA from './cta';
import './cta-list.scss';

const CTAList = ({customClass = ""}) => {
  const ctaList = useContext(ConfigContext).home.ctaList || [];
  return (
    <ul className={`ahq-cta-list ${customClass}`}>
      {ctaList.map((cta) => { return (
        // <li key={crypto.randomUUID()} className={`ahq-cta ${cta.framed?"ahq-cta--framed":""}`}>
        //   <span className="ahq-cta__image" style={{backgroundImage: `linear-gradient(#000, #000), url(${cta.background})`}} />
        //   <h3 className="ahq-cta__title">
        //     {cta.title}
        //   </h3>
        // </li>
        <CTA key={crypto.randomUUID()} {...cta} />
      )})}
    </ul>
  )
}

export default CTAList;

