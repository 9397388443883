import React from 'react';
import './hero-list.scss';

const HeroList = ({list = [], light = false}) => {
  return (
    <section className='ahq-hero-list-wrapper'>
      <ul className={`ahq-hero-list ${light?'ahq-hero-list--light':''}`}>
        {list.map(item => <li key={item} className='ahq-hero-list__item'>{item}</li>)}
      </ul>
    </section>
  )
}

export default HeroList;