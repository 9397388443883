import React from 'react';
import './slot-machine-banner.scss';

const SlotMachineBanner = () => {
  return (
    <h2 className='ahq-slot-machine-banner'>
      <span className="ahq-slot-machine-banner__fixed">
      <span className="ahq-slot-machine-banner__spinner">
        <div>UX</div>
        <div>EX</div>
        <div>UX</div>
      </span>UXPERIENCE</span>
    </h2>
  )
}

export default SlotMachineBanner;