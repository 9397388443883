import React from 'react';
import './links-list.scss';
import PassLink from '../pass-link/pass-link';

const LinksList = ({links = [], mobileColum = false}) => {
  return (
    <ul className={`ahq-links-list ${mobileColum?'ahq-links-list--mob-column':''}`}>
      {links.map((link)=>{ return (
        // <a key={link.text} className="ahq-links-list__item" href={link.url} target="_blank" download={link.download}>{link.text}</a>
        <PassLink key={link.text} link={link.url}>
          <span className="ahq-links-list__item">{link.text}</span>
        </PassLink> 
      )})}
    </ul>
  )
}

export default LinksList;