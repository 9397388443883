import React, {useContext, useEffect, useState, useCallback} from 'react';
import './pass-link.scss';
import Modal from '../modal/modal';
import { ConfigContext } from '../../App';

const PassLink = ({link, children}) => {
  const pass = useContext(ConfigContext).home.linkPass;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [passInput, setPassInput] = useState("");
  const [hasPassError, setHasPassError] = useState(false);
  
  function callModal () {
    setIsModalVisible(true);
  }

  function callLink () {
    const checkResult = isCheckPass();
    setHasPassError(!checkResult);
    if (checkResult) {
      setIsModalVisible(false);
      window.open(link, "ahq link");
    }
  }

  function isCheckPass () {
    return (passInput === pass);
  }

  const onEscapefn = useCallback((event) => {
    if (event.code==="Escape"){
      setIsModalVisible(false);
    }
  });

  function checkIntro (e) {
    if(e.code === "Enter") {
      callLink();
    }
  }

  useEffect(()=>{
    if (isModalVisible) {
      window.addEventListener('keydown', onEscapefn);
    } else {
      window.removeEventListener('keydown', onEscapefn);
      setHasPassError(false);
    }
    return ()=> window.removeEventListener('keydown', onEscapefn);
  }, [isModalVisible]);

  return (
    <>
      <span className="ahq-pass-link" onClick={callModal}>{children}</span>
      {
        isModalVisible && (
          <Modal isVisible={isModalVisible} onClose={()=>setIsModalVisible(false)}>
            <div className="ahq-pass-link__input-wrapper">
              <input className="ahq-pass-link__input" type="password" autoFocus onChange={(e)=>setPassInput(e.target.value)} placeholder='Contraseña' onKeyDown={checkIntro}/>
              <span className={`ahq-pass-link__error ${hasPassError?'ahq-pass-link__error--show': ''}`}>*La contraseña introducida no es correcta.</span>
            </div>
            <button className="ahq-pass-link__button" type="button" onClick={callLink}>Aceptar <span className="ahq-pass-link__button-arrow">🡒</span></button>
          </Modal>
        )
      }
    </>
  )
}

export default PassLink;
