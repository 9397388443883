import React, { useContext } from 'react';
import {HomeTemplate, HomeHeader} from '../templates/home';
import SiteLogo from '../components/site-logo/site-logo';
import LinksList from '../components/links-list/links-list';
import HeroList from '../components/hero-list/hero-list';
import TextBlock from '../components/text-block/text-block';
import SlotMachineBanner from '../components/slot-machine-banner/slot-machine-banner';
import CTAList from '../components/cta-list/cta-list';
import { ConfigContext } from '../App';

const HomeView = () => {
  const config = useContext(ConfigContext);

  return (
    <HomeTemplate>
      <section className="ahq-home-template__introduction">
        <HomeHeader>
          <SiteLogo />
          <LinksList links={config.home.header.links}/>
        </HomeHeader>
        <HeroList list={config.home.header.heroList} />
      </section>
      <hr/>
      <section className="ahq-home-template__body" id="ahq-home-template__body">
        <SlotMachineBanner />
        <TextBlock />
        <CTAList />
        <HeroList light={true} list={config.home.formerCustomers} />
      </section>
      <footer className="ahq-home-template__footer">
        <LinksList links={config.home.header.links} />
      </footer>
    </HomeTemplate>
  );
}

export default HomeView;
